


























































































import { defineComponent } from '@vue/composition-api'
import { useMutations } from '@u3u/vue-hooks'
import { ProfilesItem } from '@/inc/types'
import { getEnvUrl } from '@/inc/app.config'

export default defineComponent({
  name: 'profiles-item',
  props: {
    content: {
      type: Object as () => ProfilesItem,
      default: {} as ProfilesItem,
    },
    modifier: {
      type: String,
      default: '',
    },
  },
  setup(_props, ctx) {
    const api = getEnvUrl
    const { BACKTO_ADD: setBackTo } = useMutations('backTo', ['BACKTO_ADD'])

    const onClick = () => {
      setBackTo(ctx.root.$route.path)
    }

    return {
      api,
      onClick,
    }
  },
})
