































































































































import VSelect from '@/components/form/VSelect.vue'
import axios from 'axios'
import { defineComponent, ref } from '@vue/composition-api'
import { parseRoute } from '@/inc/utils'
import { ProfilesList } from '@/inc/types'

export default defineComponent({
  name: 'profiless-list',
  props: {
    content: {
      type: Object as () => ProfilesList,
      default: {} as ProfilesList,
    },
  },
  components: {
    VSelect,
  },

  setup(props, ctx) {
    const itemsRef = ref(props.content.news.content)

    const { api } = parseRoute(ctx.root.$route)
    const job = ref('')
    const currentPage = ref(1)

    const loadMore = () => {
      currentPage.value += 1

      axios.get(`${api}/pages/blog?page=${currentPage.value}`).then(res => {
        if (res.data) {
          itemsRef.value = [...itemsRef.value, ...res.data.content.news.content]
        }
      })
    }

    return {
      itemsRef,
      job,
      loadMore,
      currentPage,
    }
  },
})
